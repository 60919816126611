import "../styles/index.css"
import { SessionProvider } from "next-auth/react"

// import * as Sentry from "@sentry/node"
import { createStore, StateMachineProvider } from "little-state-machine"
import App from "next/app"
import HeadTitle from "@/components/HeadTitle"
import React from "react"
import { ToastProvider } from "react-toast-notifications"

import Script from "next/script"

import "@gouvfr/dsfr/dist/core/core.min.css"

import "@gouvfr/dsfr/dist/utility/icons/icons-system/icons-system.min.css"
import "@gouvfr/dsfr/dist/utility/icons/icons-user/icons-user.min.css"
import "@gouvfr/dsfr/dist/utility/colors/colors.min.css"
import "@gouvfr/dsfr/dist/component/button/button.min.css"
import "@gouvfr/dsfr/dist/component/input/input.min.css"

import { initMatomo } from "@/lib/matomo"
import { logDebug } from "@/utils/logger"

// Sentry.init({
//   dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
// })

function log(store) {
  logDebug("Form state in session storage", store)
  return store
}

createStore({}, { middleWares: [log] })

class MyApp extends App {
  componentDidMount() {
    initMatomo({
      piwikUrl: process.env.NEXT_PUBLIC_MATOMO_URL,
      siteId: process.env.NEXT_PUBLIC_MATOMO_SITE_ID,
    })
  }
  render() {
    const { Component, pageProps, session } = this.props
    // Workaround for https://github.com/zeit/next.js/issues/8592
    const { err } = this.props
    const modifiedPageProps = { ...pageProps, err }

    return (
      <>
        <HeadTitle />
        <div className="min-h-screen">
          <SessionProvider session={session}>
            <StateMachineProvider>
              <ToastProvider>
                <Component {...modifiedPageProps} />{" "}
              </ToastProvider>
            </StateMachineProvider>
          </SessionProvider>
          <Script
            src="/dsfr.module.min.js"
            strategy="lazyOnload"
            type="module"
          ></Script>
          <Script
            noModule
            src="/dsfr.nomodule.min.js"
            strategy="lazyOnload"
            type="text/javascript"
          ></Script>
        </div>
      </>
    )
  }
}

export default MyApp
